<footer>
  <div class="footer">
    <div>
      <small>{{ 'appCompanyName' | translate }}</small>
      <small>&#169; {{ now | date: 'yyyy' }}</small>
      <br />
      <small style="color: #aaa">{{ 'shared.version' | translate }}:</small>
      <small style="color: #aaa">{{ this.environmentVersionNumber }}</small>
      @if (environmentVersionNumber !== versionNumber) {
        <small style="color: #aaa">
          {{ ' - ' + versionNumber }}
        </small>
      }
    </div>
    <app-feedback-trigger />
  </div>
</footer>
