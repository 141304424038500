import { Component, inject, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base/base.component';
import { SystemSettingsService } from '@shared/services/system-settings.service';
import { takeUntil } from 'rxjs';
import { environment } from '@root/environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { FeedbackTriggerComponent } from '@root/app/feedback';

@Component({
  standalone: true,
  imports: [TranslateModule, DatePipe, FeedbackTriggerComponent],
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends BaseComponent implements OnInit {
  now: Date = new Date();
  environmentVersionNumber: string = environment.versionNumber;
  versionNumber = '';

  private settingService = inject(SystemSettingsService);

  ngOnInit(): void {
    this.getVersionNumber();
  }

  getVersionNumber() {
    this.settingService
      .getSystemConfig()
      .pipe(takeUntil(this.destroySubject))
      .subscribe((res) => {
        this.versionNumber = res.versionNumber;
      });
  }
}
